.HomePagePublic {
    // min-height: calc(100vh - 60px);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    
    // min-height: calc(100vh - 90px);

    // justify-content: flex-end;


    background: #f3f3ee;

    .MainContent {
        flex-grow: 1;

        .container {
            max-width: 1100px;

            .InfoCardsGrid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                margin-right: 20px;
                .InfoCard {
                    min-width: calc(100% - 20px);
                    h3 {
                        text-align: center;
                    }
                }
            }
            
            .InfoCard {
                padding: 15px 25px 20px;
                border-radius: 6px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                background-color: #fff;
                box-sizing: border-box;
                margin: 20px 20px 20px;
                // cursor: pointer;
                
                min-width: 240px;

                a {
                    color: #008CFB;
                    &:hover {
                        text-decoration: underline;
                    }
                }

            }

            .Option {
                text-align: left;
                font-size: 14px;
                cursor: pointer;
                display: block;
                font-weight: 500;
                border-radius: 20px;
                color: #008dfb;
                padding: 3px 10px;
                margin: 3px 0;
        
        
        
                &:hover {
                    background-color: #f1f7fd;
                    color: #2c2f3c;
                }
        
                &.primary {
                    color: #2c2f3c;
                }
        
                
                &.secondary {
                    font-size: 12px;
                    color: #2c2f3c;
                    font-weight: 400;
                    display: flex;
                    
                    &:hover {
                        background-color: transparent;
                        .title {
                            text-decoration: underline;
                            color: #2c2f3c;
                        }
                    }
                }
        
                &.bold {
                    font-weight: 600;
                }
        
        
                .icon {
                    margin-right: 15px;
                    &.hover {
                        display: none;
                    }
                    img {
                        width: 16px;
                        // width: 18px;
                    }
                }
        
                &:hover {
                    .icon {
                        display: none;
                        &.hover {
                            display: block;
                        }
                    }
                }
        
                &.logout {
                    font-weight: 600;
                    &:hover {
                        color: #fff;
                        background-color: #008dfb;
                    }
                }
        
            }

        }
    }

}

.HomePagePublicFooterBackground {
    height: 260px;
    background-position: center;
    background-repeat: no-repeat;
}

// .SuperAdminPageUtilities {
//     .UtilityCard {
//         padding: 15px 25px 20px;
//         border-radius: 6px;
//         box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
//         background-color: #fff;
//         box-sizing: border-box;
//         margin: 20px 20px 20px;
//         cursor: pointer;
        
//         min-width: 240px;
//     }

//     .Label {
//         display: flex;
//         margin: 20px 0;
//         justify-content: center;
//         align-items: center;
//         i {
//             flex-grow: 1;
//             background: #e9e9e9;
//             height: 1px;
//         }
//         span {
//             padding: 3px 14px;
//             border-radius: 20px;
//             font-size: 12px;
//             font-weight: 600;
//             margin: 0 10px;
//             color: #2c2f3c;
//             background-color: #e1f1fe;
//         }
//     }

//     h1 {
//         text-align: center;
//         margin: 0;
//         font-size: 28px;
//         color: #2c2f3c;
//     }

//     p {
//         text-align: center;
//     }
//     .tagline {
//         font-size: 12px;
//         color: #2c2f3c;
//         font-weight: 600;
//         margin: 20px 0;
//     }

//     .control {
//         width: calc(100% - 40px);

//         position: relative;
//         margin: 0 auto;
//         box-sizing: border-box;
//         max-width: 320px;

//         .ControlIcon {
//             display: flex;
//             width: 38px;
//             height: 34px;

//             position: absolute;
//             top: 1px;
//             right: 0px;

//             border-bottom-left-radius: 4px;
//             border-top-left-radius: 4px;

//             justify-content: center;
//             align-items: center;
//             font-size: 18px;
//             color: #929292;
//         }

//     }

//     input[type="password"], input[type="email"], input[type="text"] {
//         width: 100%;
//         display: block;
//         box-sizing: border-box;
//         margin: 10px 0;
//         padding: 10px;
//         border-radius: 30px;
//         // border: 1px solid lightgray;
//         height: 36px;
//         padding-left: 20px;
//         // background-color: rgba(204, 232, 254, 0.3); // blue
//         background-color: #f1f7fd; // blue
//         border: 2px solid #f1f7fd;
        
//         &:hover {
            
//             border-color: #ddedfd;
//             // background-color: rgba(204, 232, 254, 0.2); // blue
//         }
        
//         &:focus {
//             outline: none;
//             border-color: #ddedfd !important;
//             background-color: #fff !important;
//         }

//         &::placeholder {
//             color: #000;
//             font-weight: 600;
//         }
//     }

//     input[type="submit"] {
//         display: block;
//         /* width: 100%; */
//         border: none;
//         padding: 5px 40px 5px 30px;
//         background: #008dfb;
//         /* margin-top: 20px; */
//         color: #fff;
//         text-transform: uppercase;
//         font-size: 16px;
//         letter-spacing: 2px;
//         cursor: pointer;
//         // margin: 35px auto 30px;
//         margin: 15px auto 30px;
//         border-radius: 30px;
//         background-repeat: no-repeat;
//         background-position: right 23px top 7px;
//         outline: none;
//     }

//     .buttons {
//         display: flex;
//         justify-content: center;
//         // margin-top: 40px;
//         margin-top: 15px;
//         button, .button  {
//             box-sizing: border-box;
//             display: block;
//             // border-radius: 60px;
            
//             background: #008dfb;
//             color: #ffffff;
//             font-size: 15px;
//             line-height: 24px;
//             font-weight: 500;
//             text-align: center;
//             // padding: 1px 27px;
//             padding: 1px 25px;
//             margin: 0 10px;
//             border: 2px solid #008dfb;
//             font-family: inherit;
//             cursor: pointer;
    
//             border-radius: 17px;

//             &:hover {
//                 border: solid 2px rgba(0, 0, 0, 0.05);
//                 background-color: #0086ef;
//             }
            
//             &.secondary {
//                 background-color: #fff;
//                 color: #008dfb;
//                 border-color: #cce8fe;

//                 &:hover {
//                     border: solid 2px rgba(0, 141, 251, 0.5);
//                 }
//             }

//             &[disabled] {
//                 background: #e4e4e4;
//                 color: #ffffff;
//                 border: 2px solid #d6d6d6;
//                 cursor: default;
//             }

//         }
//     }

//     input, button {
//         &:focus {
//             outline: none;
//         }
//     }
// }
