$minDesktop: 801px;

.RaceEditContent {
    // max-width: 520px;
    margin: 20px auto;
    background-color: #fff;


    padding: 15px 25px 20px;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    box-sizing: border-box;
    // margin: 20px 20px 20px;
    // cursor: pointer;
    
    // min-width: 800px;
    min-width: 800px;


    .FormFooter {
        max-width: 320px;
    }

    .control {
        // display: flex;
        // align-items: center;
        position: relative;

        // max-width: 320px;

                        
        .label {
            display: flex;
            justify-content: space-between;
            color: #4a4a4a;
            font-weight: 400;
            font-size: 11px;
            // margin-left: 21px;
            // margin-bottom: 5px;
            margin: 0 20px 5px 21px;

            &.error {
                // color: #c11515;
            }

            &.with-right-label {
                display: flex;
                justify-content: space-between;

                a {
                    color: #2c2f3c;
                    &:hover {
                        color: #008dfb;
                    }
                }
                
            }
            
        }


        input {
            background-color: rgb(241,248,253);
            border: 2px solid rgb(241,248,253);
            color: #000; // #2c2f3c;
            display: block;
            width: 100%;
            box-sizing: border-box;
            background-repeat: no-repeat;
            background-position: right 10px center;

            font-size: 12px;
            font-weight: 600;
            padding: 10px 22px;
            background-size: 20px;
            border-radius: 16px;
            @media screen and (min-width: $minDesktop) {
                font-size: 12px;
                padding: 11px 20px;
                background-size: 16px;
                border-radius: 21px;
            }

            &:hover {
                // border-color: rgb(221,238,253);
                // border-color: #2c2f3c;
                border-color:rgba(204, 232, 254, 0.75);;
                
            }
            
            &:focus {
                background-color: #fff !important;
                border-color: rgb(221,238,253);
                outline: none;
            }

            &[disabled] {
                background: rgb(246,247,247);
                color: #000000;
                border: 2px solid rgb(246,247,247);
                cursor: default;
            }
        }
        &.error input {
            background-color: rgb(252,239,236);
            border: 2px solid rgb(252,239,236);
            &:hover {
                border-color: rgb(250,226,218);
            }
        }

        &.valid, &.invalid {

        }

        .IconSwappableValid {
            position: absolute;
            bottom: 8px;
            right: 8px;
            width: 26px;
            height: 26px;
            .icon {display: block;}
            .valid { display: none; }
            .invalid {display: none;}
        }


        &.invalid {
            input[type="password"], input[type="email"], input[type="text"] {
                background-color: #ffe1d9;
                border-color: #ffe1d9;
                &:hover {
                    border-color: #f9e2d9;
                }
            }
            .IconSwappableValid {
                .icon { display: none !important ; }
                .valid { display: none !important; }
                .invalid { display: block; }
            }
        }
        &.valid {
            input[type="password"], input[type="email"], input[type="text"] {
                background-color: #f0faf3;
                border-color: #f0faf3;
                &:hover {
                    border-color: #daf4e0;
                }
            }
            .IconSwappableValid {
                .icon { display: none !important ; }
                .valid { display: block; }
                .invalid { display: none !important; }
            }
        }
        

        

        .HometownState {
            position: absolute;
            bottom: 13px;
            right: 35px;
            font-size: 12px;
            font-weight: 600;
            cursor: pointer;
            user-select: none;
        }

        .StatesListAnchor {
            position: relative;

            .StatesList {
                display: none;
                // position: absolute;
                // top:0;
                // left:0;
                // width:100%;

            }
        }

        &.open {
            input {
                border-radius: 21px 21px 0 0;
                border-color: #ddeefd;
                background-color: #fff;
            }
            
            .StatesList {
                // display: flex;

                &.DropdownList {
                    display: flex;
            
                    margin-top: 15px;
                    max-height: 250px;
                    position: absolute;
                    background: white;
                    z-index: 1;
                    width: 100%;
                    border: 2px solid #ddeefd;
                    left: 0px;
                    top: -18px;
                    border-top: none;
                    box-sizing: border-box;
                    border-radius: 0 0 18px 18px;
            
                    padding: 4px 10px 10px;
            
                    .DropdownListInner {
                        max-height: 300px;
                        overflow: auto;
                        flex-grow: 1;

                        &::-webkit-scrollbar-track {
                            background: transparent;
                        }
                        
            
                        &::-webkit-scrollbar {
                            width: 6px;
                        }
            
                        &::-webkit-scrollbar-thumb {
                            width: 6px;
                            border-radius: 3px;
                            background-color: #008dfb;
                        }
                        
                        &::-webkit-scrollbar-thumb:window-inactive {
                            background: #6b7c88;
                        }
                    }
                    
            
                    .DropdownOption {
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
            
                        border-radius: 18px;
                        padding: 3px 20px 2px 16px;
            
            
                        &.selected {
                            font-weight: 600;
                            .checkbox {
                            }
                        }
                        
                        &:hover {
                            background-color: rgb(241,248,253);
                        }
                        
                        .flag {
                            padding-right: 7px;
                            position: relative;
                            top: -2px;
                        }
                        .title {
                            flex-grow: 1;
                        }
                    }
                }
                
                
            }
        }


        .RevealButton {
            background-color: none;
            position: absolute;
            right: 0;
            bottom: 0px;
        }

        @media screen and (min-width: $minDesktop) {
            margin: 20px auto;
        }
    }

    .buttons {
        display: flex;
        justify-content: center;
        margin-top: 40px;

        .yesno {
            padding: 1px 27px;
        }
        
        button, .button  {
            box-sizing: border-box;
            display: block;
            // border-radius: 60px;
            
            background: #008dfb;
            border: 2px solid #008dfb;

            color: #ffffff;
            font-size: 17px;
            line-height: 24px;
            font-weight: 500;
            text-align: center;
            // padding: 1px 27px;
            padding: 4px 20px;
            margin: 0 10px;
            font-family: inherit;
            cursor: pointer;
            
            border-radius: 17px;
            
            &:hover {
                border: solid 2px rgba(0, 0, 0, 0.05);
                background-color: #0086ef;
            }
            
            &.alert{
                background: #e61430;
                border: 2px solid #000;
                
                &:hover {
                    border: solid 2px #e61430;
                    background-color: #FFF;
                    color: #000;
                }

            }
            
            &.secondary {
                background-color: #fff;
                color: #008dfb;
                border-color: #cce8fe;

                &:hover {
                    border: solid 2px rgba(0, 141, 251, 0.5);
                }
            }

            &[disabled] {
                background: #e4e4e4;
                color: #ffffff;
                border: 2px solid #d6d6d6;
                cursor: default;
            }
            
        }
        
    }

    input, button {
        &:focus {
            outline: none;
        }
    }

    .checkpoints_container {
        .button {
            box-sizing: border-box;
            display: inline-block;
            background: #008dfb;
            color: #ffffff;
            font-size: 17px;
            line-height: 24px;
            font-weight: 500;
            text-align: center;
            padding: 4px 20px;
            margin: 8px 10px;
            border: 2px solid #008dfb;
            font-family: inherit;
            cursor: pointer;
            border-radius: 17px;
        }
    }

}
