$minDesktop: 801px;

.AdBuilder {

}
.AdBuilderContent {
    // max-width: 520px;
    margin: 20px auto;
    background-color: #fff;


    padding: 15px 25px 20px;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    box-sizing: border-box;
    // margin: 20px 20px 20px;
    // cursor: pointer;
    
    min-width: 800px;


    input[type=range] {
        height: 25px;
        -webkit-appearance: none;
        margin: 10px 0;
        width: 100%;
      }
      input[type=range]:focus {
        outline: none;
      }
      input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        background: #cce8fe;
        // background: #2497E3;
        border-radius: 1px;
        border: 0px solid #000000;
      }
      input[type=range]::-webkit-slider-thumb {
        // box-shadow: 0px 0px 0px #000000;
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
        // border: 1px solid #2497E3;
        border: 1px solid #cce8fe;
        height: 13px;
        width: 13px;
        border-radius: 8px;
        // height: 18px;
        // width: 18px;
        // border-radius: 25px;
        // background: #A1D0FF;
        background: #FFF;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -4px;
        // margin-top: -7px;
      }
      input[type=range]:focus::-webkit-slider-runnable-track {
        background: #cce8fe;
        // background: #2497E3;
      }
      input[type=range]::-moz-range-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        animate: 0.2s;
        box-shadow: 0px 0px 0px #000000;
        background: #cce8fe;
        // background: #2497E3;
        border-radius: 1px;
        border: 0px solid #000000;
      }
      input[type=range]::-moz-range-thumb {
        // box-shadow: 0px 0px 0px #000000;
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
        // border: 1px solid #2497E3;
        height: 13px;
        width: 13px;
        border-radius: 8px;
        // height: 18px;
        // width: 18px;
        // border-radius: 25px;
        // background: #A1D0FF;
        background: #FFF;
        cursor: pointer;
      }
      input[type=range]::-ms-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        color: transparent;
      }
      input[type=range]::-ms-fill-lower {
        background: #cce8fe;
        // background: #2497E3;
        border: 0px solid #000000;
        border-radius: 2px;
        box-shadow: 0px 0px 0px #000000;
      }
      input[type=range]::-ms-fill-upper {
        background: #cce8fe;
        // background: #2497E3;
        border: 0px solid #000000;
        border-radius: 2px;
        box-shadow: 0px 0px 0px #000000;
      }
      input[type=range]::-ms-thumb {
        margin-top: 1px;
        // box-shadow: 0px 0px 0px #000000;
        box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
        // border: 1px solid #2497E3;
        height: 13px;
        width: 13px;
        border-radius: 8px;
        // height: 18px;
        // width: 18px;
        // border-radius: 25px;
        // background: #A1D0FF;
        background: #FFF;
        cursor: pointer;
      }
      input[type=range]:focus::-ms-fill-lower {
        background: #cce8fe;
        // background: #2497E3;
      }
      input[type=range]:focus::-ms-fill-upper {
        background: #cce8fe;
        // background: #2497E3;
      }




}
