ul.stepTabs {
    display: flex;
    margin: 20px 0 20px 0;
    padding: 0;

    li {
        padding: 6px 4px;
        display: block;
        border-bottom: 2px solid gray;
        flex: 1;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        text-align: center;
        user-select: none;
        color: #ccc;
        cursor: default;
        
        &.available {
            cursor: pointer;
            color: #000;
            &:hover {
                background-color: rgba(0,0,0,0.5);
            }
        }

        &.active {
            cursor: default;
            color: gray;
            background-color: #008dfb;
            color: #fff;
            &:hover {
                background-color:#008dfb;
            }
        }


    }
}