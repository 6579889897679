$minDesktop: 801px;

.userAvatarContainer {
    display: block;
    border-radius: 50%;
    border: 4px solid #cce8fe;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    
    // padding: 4px;
    width: 90px;
    height: 90px;

    .tooltiptext {
        min-width:90px !important;
        // top: -41% !important;
        top: 115% !important;
        // margin-left: -65px !important;
        // left: 56% !important;
        left: 7% !important;
        // line-height: 15px;

        
        // padding: 5px 10px;
        // height: 32px !important;
        // line-height: 15px;
        // font-family: "Montserrat", sans-serif;
        // font-size: 13px;
        font-size: 12px !important;
        
        em {
            font-size: 12px;
            
            font-weight: 500;
            display: block;
            white-space: nowrap;

            color: #71e1f4;
            font-style: normal;
        }

        &:after{
            display: none;
        }
    }



    .userAvatar {
        width: 82px;
        height: 82px;
        border-radius: 50%;
        cursor: pointer;


    }

    .UserAvatarHoverMessage {
        position: absolute;
        bottom: -40px;
        font-size: 11px;
        color: #2c2f3c;
        text-align: center;
        width: 122px;
        left: -20px;
    }

    &.uploadedImage {
        padding: 4px;
        .userAvatar {
            width: 74px;
            height: 74px;
        }
    }
}

.AccountProfile {

    min-height: 100vh;
    display: flex;
    flex-direction: column;

    justify-content: flex-end;

    .MainAthleteProfileBlock {

        //LOGO MOVE
        // margin-top: 80px;
        background-position: bottom -19px center;
        background-size: 1700px;
        background-repeat: no-repeat;
        background-color: #F3F3EE;
    
        
        // display: flex;
    
        
        
        .ProfileCard {
            margin: 40px auto;
            max-width: 400px;
            min-height: 200px;
            box-shadow: 0 3px 7px 0 rgba(0,0,0,0.1);
            border-radius: 12px;
            background-color: #fff;
            
            @media screen and (min-width: $minDesktop) {
                display: flex;
            }

            &>* {
                flex-grow: 1;
            }


            .UserCard {
                box-sizing: border-box;
                // max-width: 376px;
                width: 400px;
                max-width: 400px;
                // min-height: 545px;
                min-height: 606px;
                border-radius: 12px 0px 0px 12px;

                text-align: center;
                // background-color: #f9f9f6;
                background-color: #ffffff;
                padding-bottom: 150px;
                background-repeat: no-repeat;
                background-position: center bottom;
                background-size: 400px;

                display: none;

                @media screen and (min-width: $minDesktop) {
                    display: block;
                }

                h1 {
                    color: #008dfb;
                    margin: 14px 0 30px;
                    font-size: 24px;
                    // padding: 15px;
            
                    // margin-bottom: 10px;
                    // margin-top: 15px;
            
                    @media screen and (max-width: 600px) {
                        margin-bottom: 5px;
                        font-size: 22px;
                    }
            
                    .athleteName {
            
                        .tooltiptext {
                            min-width: 50px !important;
                            margin-left: -56px !important;
                            // top: 129% !important;
                            top: 540% !important;
                            line-height: 15px;
                    
                            font-size: 12px;
                    
                            padding: 5px 10px;
                            // height: 32px !important;
                            // line-height: 15px;
                            // font-family: "Montserrat", sans-serif;
                            // font-size: 13px;
                    
                            font-weight: normal;
                            em {
                              color: #71e1f4;
                              
                              font-style: normal;
                              display: block;
                              white-space: nowrap;
                            }

                            &:after{
                                display: none;
                            }
                        }
                    }
                    
            
                    
                    span:hover {
                        background-color:rgba(204, 232, 254, 0.3);
                        color: #2c2f3c;
                        // cursor: pointer;
                        cursor: default;
            
                        padding-left: 16px;
                        padding-right: 16px;
                        border-radius: 18px;
                    }
                }


                .Label {
                    margin-top: 60px;
                }

                .UserInfoTable {
                    display: flex;
                    justify-content: space-between;
                    font-size: 13px;
                    line-height: 20px;
                    color: #2c2f3c;
                    position: relative;
                    
                    font-weight: normal;
                    color: #2c2f3c;
                    margin: 16px 30px;
                    
                    strong {
                        font-weight: 600;
                        
                    }

                    .left-aligned {
                        text-align: left;
                    }
                    .right-aligned {
                        text-align: right;
                    }
                }

                .Flag {
                    font-size: 36px;
                }
                .Location {
                    margin: 2px auto 0;
                    font-size: 15px;
                    font-weight: 500;
                }

                

            }
            
            
            .ProfileManageCard {
                width: 400px;
                max-width: 400px;

                min-height: 606px;

                padding: 30px 10px;
                background-repeat: no-repeat;
                background-position: center bottom;
                background-size: 400px;

                .ToggleButton {
                    margin: 0 auto 30px;
                    &>div {
                        text-transform: none;
                        padding: 3px 10px;
                    }
                }


                .ManageUserContent, .ManageAdminContent {

                    max-width: 320px;
                    margin: 0 auto;

                    .FormFooter {
                        max-width: 320px;
                    }

                    .control {
                        // display: flex;
                        // align-items: center;
                        position: relative;
                
                        max-width: 320px;

                                        
                        .label {
                            display: flex;
                            justify-content: space-between;
                            color: #4a4a4a;
                            font-weight: 400;
                            font-size: 11px;
                            // margin-left: 21px;
                            // margin-bottom: 5px;
                            margin: 0 20px 5px 21px;

                            &.error {
                                // color: #c11515;
                            }

                            &.with-right-label {
                                display: flex;
                                justify-content: space-between;

                                a {
                                    color: #2c2f3c;
                                    &:hover {
                                        color: #008dfb;
                                    }
                                }
                                
                            }
                            
                        }


                        input {
                            background-color: rgb(241,248,253);
                            border: 2px solid rgb(241,248,253);
                            color: #000; // #2c2f3c;
                            display: block;
                            width: 100%;
                            box-sizing: border-box;
                            background-repeat: no-repeat;
                            background-position: right 10px center;

                            font-size: 12px;
                            font-weight: 600;
                            padding: 10px 22px;
                            background-size: 20px;
                            border-radius: 16px;
                            @media screen and (min-width: $minDesktop) {
                                font-size: 12px;
                                padding: 11px 20px;
                                background-size: 16px;
                                border-radius: 21px;
                            }

                            &:hover {
                                // border-color: rgb(221,238,253);
                                // border-color: #2c2f3c;
                                border-color:rgba(204, 232, 254, 0.75);;
                                
                            }
                            
                            &:focus {
                                background-color: #fff !important;
                                border-color: rgb(221,238,253);
                                outline: none;
                            }

                            &[disabled] {
                                background: rgb(246,247,247);
                                color: #000000;
                                border: 2px solid rgb(246,247,247);
                                cursor: default;
                            }
                        }
                        &.error input {
                            background-color: rgb(252,239,236);
                            border: 2px solid rgb(252,239,236);
                            &:hover {
                                border-color: rgb(250,226,218);
                            }
                        }

                        &.valid, &.invalid {

                        }

                        .IconSwappableValid {
                            position: absolute;
                            bottom: 8px;
                            right: 8px;
                            width: 26px;
                            height: 26px;
                            .icon {display: block;}
                            .valid { display: none; }
                            .invalid {display: none;}
                        }


                        &.invalid {
                            input[type="password"], input[type="email"], input[type="text"] {
                                background-color: #ffe1d9;
                                border-color: #ffe1d9;
                                &:hover {
                                    border-color: #f9e2d9;
                                }
                            }
                            .IconSwappableValid {
                                .icon { display: none !important ; }
                                .valid { display: none !important; }
                                .invalid { display: block; }
                            }
                        }
                        &.valid {
                            input[type="password"], input[type="email"], input[type="text"] {
                                background-color: #f0faf3;
                                border-color: #f0faf3;
                                &:hover {
                                    border-color: #daf4e0;
                                }
                            }
                            .IconSwappableValid {
                                .icon { display: none !important ; }
                                .valid { display: block; }
                                .invalid { display: none !important; }
                            }
                        }
                        

                        

                        .HometownState {
                            position: absolute;
                            bottom: 13px;
                            right: 35px;
                            font-size: 12px;
                            font-weight: 600;
                            cursor: pointer;
                            user-select: none;
                        }

                        .StatesListAnchor {
                            position: relative;

                            .StatesList {
                                display: none;
                                // position: absolute;
                                // top:0;
                                // left:0;
                                // width:100%;

                            }
                        }

                        &.open {
                            input {
                                border-radius: 21px 21px 0 0;
                                border-color: #ddeefd;
                                background-color: #fff;
                            }
                            
                            .StatesList {
                                // display: flex;

                                &.DropdownList {
                                    display: flex;
                            
                                    margin-top: 15px;
                                    max-height: 250px;
                                    position: absolute;
                                    background: white;
                                    z-index: 1;
                                    width: 100%;
                                    border: 2px solid #ddeefd;
                                    left: 0px;
                                    top: -18px;
                                    border-top: none;
                                    box-sizing: border-box;
                                    border-radius: 0 0 18px 18px;
                            
                                    padding: 4px 10px 10px;
                            
                                    .DropdownListInner {
                                        max-height: 300px;
                                        overflow: auto;
                                        flex-grow: 1;

                                        &::-webkit-scrollbar-track {
                                            background: transparent;
                                        }
                                        
                            
                                        &::-webkit-scrollbar {
                                            width: 6px;
                                        }
                            
                                        &::-webkit-scrollbar-thumb {
                                            width: 6px;
                                            border-radius: 3px;
                                            background-color: #008dfb;
                                        }
                                        
                                        &::-webkit-scrollbar-thumb:window-inactive {
                                            background: #6b7c88;
                                        }
                                    }
                                    
                            
                                    .DropdownOption {
                                        display: flex;
                                        justify-content: space-between;
                                        cursor: pointer;
                            
                                        border-radius: 18px;
                                        padding: 3px 20px 2px 16px;
                            
                            
                                        &.selected {
                                            font-weight: 600;
                                            .checkbox {
                                            }
                                        }
                                        
                                        &:hover {
                                            background-color: rgb(241,248,253);
                                        }
                                        
                                        .flag {
                                            padding-right: 7px;
                                            position: relative;
                                            top: -2px;
                                        }
                                        .title {
                                            flex-grow: 1;
                                        }
                                    }
                                }
                                
                                
                            }
                        }


                        .RevealButton {
                            background-color: none;
                            position: absolute;
                            right: 0;
                            bottom: 0px;
                        }

                        @media screen and (min-width: $minDesktop) {
                            margin: 20px auto;
                        }
                    }



                    .change-password-link {
                        margin-left: 21px;
                        color: #008dfb;
                        font-size: 12px;
                        font-weight: 600;
                        cursor: pointer;
                    }
                    
                    
                }
                

                .ManageAdminContent {
                }


                ul, li {margin:0;padding:0;list-style: none;}
                .ManageAvatarContent {


                    .PersonalAvatar {
                        display: flex;
                        max-width: 270px;
                        margin: 0 auto;
                    }
                    
                    
                    .AvatarList {
                        display: grid;
                        grid-template-columns: repeat(4, 60px);
                        grid-gap: 10px;
                        margin: 0 auto 30px;
                        justify-content: center;

                        .AvatarItem {
                            width: 60px;
                            cursor: pointer;

                            border: 4px solid #f0f1f3;
                            border-radius: 60px;
                            overflow: hidden;
                            box-sizing: border-box;
                            width: 60px;
                            height: 60px;
                            &:hover {
                                border: 4px solid #d1e6fc;
                                
                            }

                            img {
                                width: 52px;
                                height: 52px;
                            }

                        }

                        &.disabled {
                            // opacity: 0.3;
                            display: none;
                            .AvatarItem {
                                cursor: default;
                            }
                        }
                    }
                }
                
            }
            
            .DatePreview {
                margin: -14px 23px 2px;
                font-size: 12px;
                font-weight: 600;
                color: orange;
            }
            
        }
        
        
    }
    
    
}