$minDesktop: 801px;

.InviteMemberModalCover {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 5;
}

.InviteMemberModalForm {
    font-family: "Montserrat", sans-serif;
    position: fixed;
    top: 0;
    left: 0;
    font-size: 12px;
    background-color: #fff;
    // padding: 40px;
    // padding: 20px 50px 170px 40px;
    padding: 20px 0 20px 0;
    box-sizing: border-box;
    min-height: 150px;
    max-height: 100vh;
    overflow: auto;
    z-index: 10;
    border-radius: 12px;
    box-shadow: 0 0 12px rgba(0,0,0,.3), 0 1px 5px rgba(0,0,0,.2);
    width: calc(100vw - 40px);
    margin: 20px;

    // // background-position: center bottom 40px, left -100px bottom 80px;
    // background-position: center top 20px, left 0px top 40px;
    // // background-size: 100px, 1280px;
    // background-size: 100px, contain;
    // background-repeat: no-repeat, no-repeat;

    .CheckboxControl {
        position: relative;
    
        
    
        .checkbtn {
            position: absolute;
            top: 1px;
            left: 0px;
            display: none;
    
            &.unselected { display: block; }
        }
    
        input[type=checkbox] {
            display: none;
    
            &:checked + label {
                .unselected { display: none; }
                .unselected_hover { display: none; }
                .selected { display: block; }
            }
        }
        label {
            padding: 2px 2px 2px 2px;
    
    
            font-size: 11px;
    
            @media screen and (min-width: $minDesktop) {
                font-size: 13px;
            }
    
            
    
            &:hover {
                .unselected { display: none; }
                .unselected_hover { display: block; }
                .selected { display: none; }
            }
        }
    
    
        // @media screen and (max-width: 600px) {
        //   margin-top: -10px;
        // }
      }

    .HeroImage {
        position: relative;

        img.CityBackground {
            width: 100%;
            margin-bottom: 18px;
        }
        img.Runner {
            position: absolute;
            width: 80px;
            height: 80px;
            bottom: -5px;
            left: 50%;
            margin-left: -40px;
        }
    }

    .ModalContent {
        padding: 20px 30px;
    }
    
    
    @media screen and (min-width: $minDesktop) {
        $width: 480px;
        margin: 0;
        margin-left: -($width/2);
        top: 40px;
        // width: 670px;
        width: $width;
        left: 50%;
        border-radius: 12px;
        // padding-bottom: 260px;
    }
    
    
    // @media screen and (min-width: 701px) {
    //     margin: 0 0 0 -330px;
    //     width: 670px;
    //     left: 50%;
    // }

    .ClearIcon {
        cursor: pointer;
        max-width: 20px;
        height: 20px;

        position: absolute;
        top: 20px;
        right: 25px;
        z-index: 10;

        &:hover {  
            .background {
                transform: scale(1);
            }
        }
    
        .background { 
            transform: scale(0);
            transition: transform 200ms;
            transition-timing-function: ease-out;
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            // background-color: #93b9ff;
            background-color: rgba(204, 232, 254, 0.3);
            top: -6px;
            left: -5px;
            z-index: -5;
        }
    }

    .closeInviteMemberModal {
        position: absolute;
        top: 10px;
        right: 25px;
        font-size: 25px;
        color: #525151;
        cursor: pointer;
    }

    .Logo {
        margin-top: 10px;
        text-align: center;


        max-width: 108px;
        margin: 10px auto 0;

        
        
        img {
            display: inline-block !important;
            max-width: 100%;
        }
        @media screen and (min-width: $minDesktop) {
            max-width: none;
        }
    }

    .title {
        font-size: 16px;
        font-weight: 800;
        color: #2c2f3c;
        margin: 10px 0;
        text-align: center;
        @media screen and (min-width: $minDesktop) {
            font-size: 28px;
            // margin: 30px;
            margin: 5px 0 5px;
        }
    }

    p {
        font-size: 12px;
        color: #2c2f3c;
        line-height: 1.5;
        display: block;
        margin: 1em auto !important;
        max-width: 500px;
        font-weight: 600;
        text-align: center;
        max-width: 370px;
        @media screen and (min-width: $minDesktop) {
            // font-size: 16px;
        }

        &.light {
            font-weight: 400;
            // color:
        }
    }

    .Label {
        display: flex;
        margin: 20px 0;
        justify-content: center;
        align-items: center;
        i {
            flex-grow: 1;
            background: #e9e9e9;
            height: 1px;
        }
        span {
            padding: 3px 14px;
            border-radius: 20px;
            font-size: 12px;
            font-weight: 600;
            margin: 0 10px;
            color: #2c2f3c;
            background-color: #e1f1fe;
        }
    }

    label {
        display: block;
        margin: 20px auto;
        text-align: left;
        box-sizing: border-box;
        max-width: 320px;

        .label {
            display: block;
            font-size: 12px;
            color: #4a4a4a;
            font-weight: 800;
            margin-left: 21px;
            margin-bottom: 5px;
            &.error {
                // color: #c11515;
            }
        }

        input {
            background-color: #e3f2ff;
            color: #2c2f3c;
            border: none;
            display: block;
            width: 100%;
            box-sizing: border-box;
            background-repeat: no-repeat;
            background-position: right 10px center;

            font-size: 12px;
            padding: 10px 22px;
            background-size: 16px;
            border-radius: 16px;
            
            &::placeholder {
                color: #000;
                font-weight: 600;
            }

            
            @media screen and (min-width: $minDesktop) {
                // font-size: 14px;
                // padding: 14px 22px;
                // background-size: 30px;
                border-radius: 21px;
            }
        }
        &.error input {
            background-color: rgba(255, 56, 0, 0.1);
        }

        @media screen and (min-width: $minDesktop) {
            margin: 20px auto;
        }
    }

    .buttons {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        button, .button  {
            box-sizing: border-box;
            display: block;
            // border-radius: 60px;
            
            background: #008dfb;
            color: #ffffff;
            font-size: 17px;
            line-height: 24px;
            font-weight: 500;
            text-align: center;
            // padding: 1px 27px;
            padding: 4px 20px;
            margin: 0 10px;
            border: 2px solid #008dfb;
            font-family: inherit;
            cursor: pointer;
    
            border-radius: 17px;

            &:hover {
                border: solid 2px rgba(0, 0, 0, 0.05);
                background-color: #0086ef;
            }
            
            &.secondary {
                background-color: #fff;
                color: #008dfb;
                border-color: #cce8fe;

                &:hover {
                    border: solid 2px rgba(0, 141, 251, 0.5);
                }
            }

            &[disabled] {
                background: #e4e4e4;
                color: #ffffff;
                border: 2px solid #d6d6d6;
                cursor: default;
            }
        }
    }

    input, button {
        &:focus {
            outline: none;
        }
    }
    

    .ThankYou {
        text-align: center;

        .ThankYouFinishIcon {
            margin: 30px auto 10px;
            width: 100px;
        }
    }


    img.runnergif {
        position: fixed;
        bottom: 0px;
        left: 50%;
        width: 100px;
        margin-left: -50px; /* <- this has to be half of the width */
        z-index: -1;
    }

    .tooltiptext {
        min-width: 33px !important;
        top: 139% !important;
        // margin-left: -65px !important;
        left: 65% !important;
        // line-height: 15px;

        
        // padding: 5px 10px;
        // height: 32px !important;
        // line-height: 15px;
        // font-family: "Montserrat", sans-serif;
        // font-size: 13px;
        
        em {
            font-size: 11px;
            
            color: #fcb452;
            font-style: normal;
            font-weight: 500;
            display: block;
            white-space: nowrap;
        }

        // &:after{
        //     display: none;
        // }
    }

    
    
    
}