


  .keepInTouchIcon {
    display: inline-block;
    cursor: pointer;

    

    img {
        display: block;
        width: 24px;
        height: 24px;

    }

  }

  
  .Header {
    height: 60px;
    text-align: center;
    // background-color: #f8f8f5;

    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);

    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    // z-index: 5;
    background-color: #f8f8f5e0;


    .HeaderTabs {
        display: flex;
        height: 61px;
        // justify-content: center;
        justify-content: left;
        padding-left: 90px;

        .athletes {
            transform: scale(-1,1);
            // margin-left: 10px;
            // margin-right: -15px;
            margin-top: 18px;

            .tooltiptext {
                transform: scale(-1,1);
                min-width: 80px;
                margin-left: -50px;
                top: 180%;
                line-height: 15px;
      
                font-size: 12px;
      
                padding: 5px 10px;
                z-index:5;
                // height: 32px !important;
                // line-height: 15px;
                // font-family: "Montserrat", sans-serif;
                // font-size: 13px;
      
                em {
                  color: #71e1f4;
                  font-style: normal;
                  display: block;
                  white-space: nowrap;
                }
            }
        }
        .athletesNoneSelected {
            transform: scale(-1,1);
            // margin-left: 10px;
            // margin-right: -15px;
            margin-right: -5px;
            margin-top: 18px;
        }

        .tabSeparator {
            width: 1px;
            background-color: #cfd8dc;
            height: 30px;
            margin-top: 15px;
            margin-right: 15px;
        }
    }

    .HeaderTab {
        outline: none;
        height: 61px;
        line-height: 61px;
        padding: 0 15px;
        font-weight: 500;
        font-size: 14px;
        color: #2c2f3c;
        position: relative;
        overflow: hidden;
        user-select: none;


        @media screen and (max-width: 500px) {
            // text-align: left;
            // padding-left: 70px;
            // text-align: center;
            font-size: 12px;
        }

        




        &:hover {
            color: #008dfb;
            &::after {
                transform: translateX(10px);
                transition: transform 300ms;
                transition-timing-function: ease-out;
            }
        }

        &::after {
            // transform: translateX(-50px);
            transform: translateX(-80px);
            content: " ";
            display: block;
            position: absolute;
            bottom: 15px;
            // left: 14px;
            // width: 30px;
            left: 5px;
            width: 50px;
            border-bottom: 1px solid #008dfb;
        }
        

        &.active {
            color: #000000;
            font-weight: 600;
            // background: #f3f3ee;
            // border-left: 1px solid #dadada;
            // border-right: 1px solid #dadada;
            cursor: default;
            

            &::after {
                display: block;

                transform: translateX(10px);
                // transition: transform 300ms;
                // transition-timing-function: ease-out;
            }
        }
        &:hover{
            color: #000000;
        }
    }



    .container {
    }
    
    .HeaderTitle {
        line-height: 60px;
        font-size: 23px;
        font-weight: 600;
        color: #2c2f3c;

        @media screen and (max-width: 700px) {
            // text-align: left;
            // padding-left: 200px;
            text-align: center;
            font-size: 16px;
        }

        @media screen and (max-width: 500px) {
            // text-align: left;
            // padding-left: 70px;
            text-align: center;
            font-size: 14px;
        }
    }


    .rightbar-container {
        position: absolute;
        top: 0;
        right: 0;

        // @media screen and (max-width: 600px) {
        //     position: fixed;
        //     // display: flex;
        //     text-align: left;
        // }

        .rightbar-container-block {


            

            &.search-icon-container {
                padding: 2px 25px 0 0;

                .tooltiptext {
                    min-width: 80px;
                    margin-left: -50px;
                    top: 155%;
                    line-height: 15px;
            
                    font-size: 12px;
            
                    padding: 5px 10px;
                    // height: 32px !important;
                    // line-height: 15px;
                    // font-family: "Montserrat", sans-serif;
                    // font-size: 13px;
            
                    em {
                      color: #71e1f4;
                      font-style: normal;
                      display: block;
                      white-space: nowrap;
                    }
                }
            }
            &.profile-icon-container {
                padding: 7px 25px 0 0;

                @media screen and (max-width: 600px) {
                    padding: 7px 10px 0 0;
                    // display: none;
                }
            }
            .rightbar-container-row-1 {
                padding-top: 18px;
            }

            &.PagesNavigation {
                height: 40px;
                display: flex;
                align-items: center;
                border-right: 1px solid #cfd8dc;
                margin-top: 10px;
                margin-right: 20px;
                padding-right: 20px;
                font-size: 12px;

                @media screen and (max-width: 600px) {
                    // margin-right: 30px;
                    // padding-right: 30px;
                    padding-right: 0;
                    border-right: 0;
                }

                // @media screen and (max-width: 600px) {
                //     // align-items: left;
                //     // margin-right: 0px;
                //     // padding-right: 0px;
                //     font-size: 10px;
                //     img {
                //         width: 15px;
                //         height: 15px;
                //     }
                // }

                strong {
                    padding: 0 5px;
                    @media screen and (max-width: 600px) {
                        padding: 0 4px;
                    }
                }

                a {
                    border: none;
                    padding: 0 5px;
                    cursor: pointer;
                    background: transparent;
                    position: relative;




                    &:hover{ 
                        .background {
                            transform: scale(1);
                        }
                    }


                    .background {
                        display: block;
                        transform: scale(0);
                        transition: transform 200ms;
                        transition-timing-function: ease-out;
                        position: absolute;
                        width: 30px;
                        height: 30px;
                        border-radius: 100%;
                        // background-color: #93b9ff;
                        background-color: rgba(204, 232, 254, 0.3);
                        // background-color: rgba(91, 91, 88, 0.3);
                        // background-color:#e8e8e0;;
                        top: 0px;
                        left: 4px;
                        z-index: 8;

  
                    }

                }
            }
        }

    }

    
}












// --------------------------------------
// --------------------------------------
// --------------------------------------

.header {
  
    @media screen and (min-width: 801px) {
      display: flex;
      justify-content: space-between;    
      &>div {
        flex: 1;
      }
    }
  
  
    .logo-container {
      display: flex;
      // padding-top: 10px;
      padding-top: 35px;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;
      /* cursor: pointer; */
      user-select: none;
      padding-left: 26px;
      @media screen and (max-width: 600px) {
        padding-top: 12px;
      }
  
      .container {
        // margin-top: -20px;
  
        .tooltip {
          .tooltiptext {
              min-width: 80px !important;
              margin-left: -52px !important;
              top: 111% !important;
              // height: 32px !important;
              // line-height: 15px;
              // font-family: "Montserrat", sans-serif;
              // font-size: 13px;
          }
        }
  
  
      }
    }
  
    .menu_button {
      border: 10px solid #fff;
      border-radius: 50px;
      position: relative;
      left: -10px;
      top: 0;
      background-color: #fff;
      display: inline-block;
      z-index: 7;
      cursor: pointer;
      
      
      
        &:hover .menu_button_img {
          display: none;
        }
        .menu_button_img {
          display: block;
        }
        .menu_button_img_hover {
          display: none;
        }
        &:hover .menu_button_img_hover {
          display: block;
        }
    }
  
    
  
    .logo-img-container {
      z-index: 7;
      position: relative;
      padding-left: 34px;
  
      img {
        width: 140px;
        display: block;
      }
  
      @media screen and (max-width: 600px) {
        padding-top: 0px;
        padding-bottom: 15px;
      }
  
    }
  
  }
  
  .header > .athlete-info-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #2c2f3c;
    font-size: 15px;
    font-weight: 500;
    flex-grow: 2;
    margin: 0 10px;
    padding-top: 25px;
    @media screen and (max-width: 600px) {
      padding-top: 0px;
      padding-bottom: 5px;
    }
  
    .DropdownContainer {
      display: inline-block;
      
      em {
          font-style: normal;
          font-size: 15px;
      }
    }
  
  }
  
  
  .social-icon {
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    outline: none;
    
  }
  
  .athlete-info {
    span.flag {
      font-size: 20px;
      display: inline-block;
      position: relative;
      top: 3px;
      padding-right: 5px;
      cursor: default;
      @media screen and (max-width: 600px) {
        padding-right: 0;
        
      }
  
    }
    a {
      font-weight: 700;
      color: #008dfb;
      text-decoration: none;
    }
    span.bib {
      display: inline-block;
      padding-left: 7px;
      @media screen and (max-width: 600px) {
        padding-left: 0;
        margin: 0 0 2px 0;
      }
    }
    .name {
      font-weight: 600;
      color: #008dfb;
  
    }
    .event-name {
      font-weight: 700;
      // color: #008dfb;
    }
    .event-location {
      font-weight: normal;
      .event-location-flag {
        position: relative;
        top: 2px;
      }
    }
    .event-date {
      font-weight: normal;
      margin-left: -12px;
  
      // .DropdownContainer {
      //   .DropdownValue {
      //     padding-left: 0px;
      //   }
      // }
  
      .tooltip {
        z-index: 7;
        .tooltiptext {
            min-width: 50px;
            margin-left: -90px;
            top: 165%;
            // font-family: "Montserrat",sans-serif;
            font-size: 13px;
            
        }
      }
  
    }
    .dot {
      padding: 0 18px;
    }
    i { /* dot */
      font-style: normal;
      color: #008dfb;
      display: inline-block;
      padding: 0 10px;
      font-weight: 600;
    }
    span.divisionType {
      font-weight: 600;
    }
    span.athleteName {
      color: #008dfb;
      &:hover {
        // background-color: #eff8fe;
        background-color:rgba(204, 232, 254, 0.3);
        color: #2c2f3c;
        cursor: pointer;
      }
      @media screen and (max-width: 600px) {
        margin: 0 0 2px 0;
      }
    }
  
    @media screen and (max-width: 600px) {
      .event-name {
        display: block;
        margin-bottom: 5px;
        &+.dot {
          display: none;
        }
      }
      .dot {
        color: #008dfb;
        font-size: 11px;
      }
      .event-location, .event-date {
        font-size: 11px;
        color: #2c2f3c;
      }
    }
  
    
  }
  .toggle-units-container {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
  }
  
  .rightbar-container {
    display: flex;
    justify-content: flex-end;
  }
  
  .rightbar-container-block {
  
    &.search-icon-container {
      display: flex;
      align-items: center;
      // padding: 25px 30px 0 0;
      padding: 20px 25px 0 0;
  
      .searchIcon {
        position: relative;
        top: 0;
        left: 0;
        right: auto;
      }
  
      @media screen and (max-width: 600px) {
        // padding-right: 0;
        display: none;
      }
      
    }
  
    &.profile-icon-container {
      padding: 20px 30px 0 0;
    }
  
    
  }
  .rightbar-container-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 30px;
    user-select: none;
    
    
    &-1 {
      padding-top: 38px;
      @media screen and (max-width: 600px) {
        // padding-top: 0;
        display: none;
      }
    }
    &-2 {
      position: relative;
      top: 16px;
      justify-content: flex-end;
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
  
    @media screen and (max-width: 600px) {
      padding-right: 0;
    }
  }
  
  .searchIcon {
    position: absolute;
      right: 50px;
      top: 40px;
      width: 30px;
      z-index: 7;
      
      @media screen and (max-width: 600px) {
        right: 20px;
        top: 40px;
      }
  }