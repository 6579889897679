

.checkpoints_container {
    li {
        input {
            border: 1px solid gray;
            padding: 3px 5px;
            border-radius: 2px;
        }
        &.invalid {
            input {
                border-color: red;;
            }
        }
    }
}