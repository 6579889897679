.SelectRace {
    // min-height: calc(100vh - 60px);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    
    // min-height: calc(100vh - 90px);

    // justify-content: flex-end;


    background: #f3f3ee;

    .MainContent {
        flex-grow: 1;

        .container {
            max-width: 1100px;

            .buttons {
                display: flex;
                justify-content: center;
                // margin-top: 40px;
        
                .yesno {
                    padding: 1px 27px;
                }
                
                button, .button  {
                    box-sizing: border-box;
                    display: block;
                    // border-radius: 60px;
                    
                    background: #008dfb;
                    color: #ffffff;
                    font-size: 17px;
                    line-height: 24px;
                    font-weight: 500;
                    text-align: center;
                    // padding: 1px 27px;
                    padding: 4px 20px;
                    margin: 0 10px;
                    border: 2px solid #008dfb;
                    font-family: inherit;
                    cursor: pointer;
            
                    border-radius: 17px;
        
                    &:hover {
                        border: solid 2px rgba(0, 0, 0, 0.05);
                        background-color: #0086ef;
                    }
                    
                    &.secondary {
                        background-color: #fff;
                        color: #008dfb;
                        border-color: #cce8fe;
        
                        &:hover {
                            border: solid 2px rgba(0, 141, 251, 0.5);
                        }
                    }
        
                    &[disabled] {
                        background: #e4e4e4;
                        color: #ffffff;
                        border: 2px solid #d6d6d6;
                        cursor: default;
                    }
                    
                    // @media screen and (min-width: $minDesktop) {
                    //     border-radius: 27px;
                    //     font-size: 24px;
                    //     padding: 15px 48px;
                    //     // margin: 50px auto;
                    //     margin: 10px auto;
                    // }
                }
                
            }

            .InfoCards {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
            }

            .InfoCard {
                padding: 15px 25px 20px;
                border-radius: 6px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                background-color: #fff;
                box-sizing: border-box;
                margin: 20px 20px 20px;
                cursor: pointer;
                
                min-width: 240px;

            }

        }
    }

}

.SelectRaceFooterBackground {
    height: 260px;
    background-position: center;
    background-repeat: no-repeat;
}
