.ScrollerItems {

    max-height: 80vh;
    overflow: auto;

    .ScrollerItemsContainer {

        tr {
            td {
                &.edited {
                    color: red;
                    background-color: yellow;;
                }
                &.invalid {
                    color: purple;
                    background-color: pink;
                }
            }
        }
    }
}