$minDesktop: 801px;

.EditModalCover {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 15;
}

.EditModalContent {
    font-family: "Montserrat", sans-serif;
    position: fixed;
    left: 0;
    font-size: 12px;
    background-color: #fff;
    // padding: 40px;
    // padding: 20px 50px 170px 40px;
    padding: 50px 50px 38px 40px;
    box-sizing: border-box;
    min-height: 150px;
    max-height: 100vh;
    overflow: auto;
    z-index: 17;
    border-radius: 12px;
    box-shadow: 0 0 12px rgba(0,0,0,.3), 0 1px 5px rgba(0,0,0,.2);
    width: calc(100vw - 40px);
    margin: 20px;
    
    // background-position: center bottom 40px, left -100px bottom 80px;
    background-position: center bottom 20px, left 0px bottom 40px;
    // background-size: 100px, 1280px;
    background-size: 100px, contain;
    background-repeat: no-repeat, no-repeat;
    
    
    transition: top 0.4s, opacity 0.2s;
    top: 0;
    // opacity: 0.2;
    opacity: 1;
    // top: 40px;
    @media screen and (min-width: $minDesktop) {
        top: 110px;
        $width: 520px;
        margin: 0; 
        margin-left: -($width/2);
        width: $width;
        left: 50%;
        border-radius: 12px;
    }

    &.activated {
        opacity: 1;
        top: 40px;
    }


}